import React from 'react';
import 'bootstrap/dist/css/bootstrap.min.css';
import './App.css';
import NavBar from './components/NavBar'
import Main from "./components/Main"

function App() {
  return (
    <div className="App">
      <NavBar></NavBar>
      <body className="Body">
        <Main />
      </body>
    </div>

  );
}

export default App;
