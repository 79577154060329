import React from 'react';
import Navbar from 'react-bootstrap/Navbar'
import Nav from "react-bootstrap/Nav"
import Button from 'react-bootstrap/Button'
import ButtonGroup from 'react-bootstrap/ButtonGroup'
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome'
import { faGithub } from '@fortawesome/free-brands-svg-icons'
import {faEnvelopeSquare} from '@fortawesome/free-solid-svg-icons'


const navStyle= {
    backgroundColor: "#0f182eB",
    color: "#F3ECE3"}

class NavBar extends React.Component {
    constructor(props) {
        super(props);

    }

    render() {
        return (
            <Navbar variant="dark" style= {navStyle}>
                <Navbar.Brand style= {navStyle} href="home">Lance Arena</Navbar.Brand>
                <Navbar.Toggle aria-controls="basic-navbar-nav" />
                <Navbar.Collapse id="basic-navbar-nav">
                    <Nav className="mr-auto"> 
                        <Nav.Link style={navStyle} href="work">Work</Nav.Link>
                        <Nav.Link style= {navStyle} href="gym">Gym</Nav.Link>
                        <Nav.Link style= {navStyle} href="chess">Chess</Nav.Link>
                        <Nav.Link style= {navStyle} href="spotify">Spotify</Nav.Link>
                        <Nav.Link style= {navStyle} href="books">Books</Nav.Link>

                    </Nav>
                    <ButtonGroup className="mr-2" aria-label="First group">
                        <Button variant="outline-light" href="mailto:lancearena@lancearena.dev">
                            <FontAwesomeIcon icon={faEnvelopeSquare}/>
                        </Button>
                        <Button variant="outline-light" onClick= {()=>window.open('https://github.com/lance-arena')}>
                            <FontAwesomeIcon icon={faGithub} />
                        </Button>{' '}
                    </ButtonGroup>
                </Navbar.Collapse>
            </Navbar>
        )
    }
}
export default NavBar;