import { Container } from '@material-ui/core';
import React from 'react';

const textStyle= {
    color: "#F3ECE3",
    position: 'absolute', 
    left: '50%', 
    top: '50%',
    transform: 'translate(-50%, -50%)'
  }

class Gym extends React.Component {
    constructor(props) {
        super(props);

    }
    render() {
        return (
            <Container>
                <div style={textStyle}>
                    <h1>
                        Currently I am doing Push Pull Legs 6 days a week. During bulking cycles I eat around 2300 calories and during
                        cutting cycles I eat around 1800 calories.
                    </h1>
                </div>
            </Container>



        );

    }
}
export default Gym;