import { Typography } from '@material-ui/core';
import React from 'react';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome'
import { faCalendarDay, faClock, faBolt, faFighterJet } from '@fortawesome/free-solid-svg-icons'

const textStyle= {
  color: "#F3ECE3",
  position: 'absolute', 
  left: '50%', 
  top: '50%',
  transform: 'translate(-50%, -50%)'
}
class Chess extends React.Component {
  constructor(props) {
    super(props);
    this.state = {
      error: null,
      isLoaded: false,
      chess: JSON
    };
  }

  componentDidMount() {
    fetch("https://api.chess.com/pub/player/lance888/stats")
      .then(res => res.json())
      .then(
        (result) => {
          console.log(result)

          this.setState({
            isLoaded: true,
            chess: result
          });
        },
        // Note: it's important to handle errors here
        // instead of a catch() block so that we don't swallow
        // exceptions from actual bugs in components.
        (error) => {
          this.setState({
            isLoaded: true,
            error
          });
        }
      )
  }

  render() {
    const { error, isLoaded, chess } = this.state;
    if (error) {
      return <div>Error</div>;
    }
    else if (!isLoaded) {
      return <div>Loading...</div>;
    } else {
      return (
        <div style={textStyle}>
            <h1>Rankings</h1>
            <Typography>
              Rankings are pulled from the Chess.com API
          </Typography>
            <Typography>
              <FontAwesomeIcon icon={faCalendarDay} /> Daily Rating: {chess.chess_daily.last.rating}
            </Typography>
            <Typography>
              <FontAwesomeIcon icon={faClock} /> Rapid Rating: {chess.chess_rapid.last.rating}
            </Typography>
            <Typography>
              <FontAwesomeIcon icon={faBolt} /> Blitz Rating: {chess.chess_blitz.last.rating}
            </Typography>
            <Typography>
              <FontAwesomeIcon icon={faFighterJet} /> Bullet Rating: {chess.chess_bullet.last.rating}
            </Typography>
            <h1>Openings</h1>
            <h2>
              White
            </h2>
            <Typography>
              <li>
                Vienna System
              </li>
              <li>
                Queens Gambit
              </li>

              <li>
                Ruy Lopez
              </li>

            </Typography>
            <h2>
              Black
            </h2>
            <Typography>
              <li>
                Caro-Khann
              </li>

              <li>
                Sicilian Defense
              </li>

            </Typography>
        </div>
      );

    }
  }
}
export default Chess;