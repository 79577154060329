import React from 'react';


const textStyle= {
  color: "#F3ECE3",
  position: 'absolute', 
  left: '50%', 
  top: '50%',
  transform: 'translate(-50%, -50%)'
}
class Books extends React.Component {
  constructor(props) {
    super(props);
  }


  render() {
    return (
      <div style={textStyle}>
          <h1>
            Books
          </h1>
          <li>
            Clean Code
          </li>
      </div>

    );

  }
}
export default Books;