import React from 'react';


const textStyle= {
  color: "#F3ECE3",
  position: 'absolute', 
  left: '50%', 
  top: '50%',
  transform: 'translate(-50%, -50%)'
}
class Home extends React.Component {
  constructor(props) {
    super(props);

  }


  render() {
    return (
      <div style={textStyle}>
          <h1 aligin='center'>
            Hey! My name is Lance Arena and I am a Back-End Software engineer.
            I graduated from St. John's University with a Bachelors in Computer Science
            and a minor in Cyber Security. This site will tell you a litte bit about myself.
            This site is made using React, Material UI, Jest, SASS and hosted on Heroku.
            This site is a work in progress so if something isn't working let me know.
          </h1>
      </div>

    );

  }
}
export default Home;