import React from 'react';

const textStyle= {
    color: "#F3ECE3",
    position: 'absolute', 
    left: '50%', 
    top: '50%',
    transform: 'translate(-50%, -50%)'
  }
class Spotify extends React.Component {
    constructor(props) {
        super(props);

    }
    render() {
        return (
            <div style={textStyle}>
                <h1>
                    Spotify Place Holder.
                </h1>
            </div>

        );

    }
}
export default Spotify;