import React from 'react';
import { Switch, Route } from 'react-router-dom';
import Chess from '../pages/Chess';
import Home from '../pages/Home';
import Work from '../pages/Work'
import Gym from '../pages/Gym'
import Spotify from '../pages/Spotify'
import Books from "../pages/Books"
const Main = () => {
  return (
    <Switch> {/* The Switch decides which component to show based on the current URL.*/}
      <Route path="/" exact component={Home}/>
      <Route exact path='/chess' component={Chess}></Route>
      <Route exact path='/home' component={Home}></Route>
      <Route exact path='/work' component={Work}></Route>
      <Route exact path='/gym' component={Gym}></Route>
      <Route exact path='/spotify' component={Spotify}></Route>
      <Route exact path='/books' component={Books}></Route>
    </Switch>
  );
}

export default Main;